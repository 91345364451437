code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.app-content {
  height: 100%;
  height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
  margin-bottom: 10px;
}

.card-search-header {
  margin-bottom: 5px;
  text-align: center;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}


.card-search .fab-card {
  font-size: 12px;
  /* width: 162px; */
  width: 100%;
}

.card-search .card-body {
  padding: 10px;
}

.card-search .fab-card>.watermark {
  font-size: 36px;
  top: 50px;
}

.fab-card {
  display: inline-block;
  position: relative;
  text-align: left;
  margin: 5px;
}

.fab-card .dropdown {
  display: inline-block;
}

.card-list .fab-card>.dis {
  /* width: 2.4375in;
  height: 3.4375in; */
  width: 235px;
    height: 329px;
  margin: 8px;
  border: 1px solid grey;
}


.search-modal {
  width: 80vw;
  max-width: none!important;
}

@media (min-width: 768px) {
  .search-modal {
    width: 90vw;
    max-width: none!important;
  }
}

@media (min-width: 992px) {
  .search-modal {
    width: 80vw;
    max-width: none!important;
  }
}


.App {
  width: 100%;
}

.main {
  padding-top: 80px;
  /* margin-bottom: 130px; */
  text-align: center;
}

.circle {
  display:inline-block;
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  margin: 2px;
}

.box {
  width:40px;
  height:40px;
  border:1px solid;
}
.box:before,
.box > div:before{
  content:"";
  float:var(--d,left);
  height:100%;
  width:50%;
  shape-outside:linear-gradient(to top var(--d,left),transparent 50%,#fff 0);
  /* To illustrate */
  /* background   :linear-gradient(to top left,transparent 50%,yellow 0); */
}
.box > div {
  height:100%;
}
.box > div:before {
  --d:right;
  /* To illustrate */
  /* background   :linear-gradient(to top right,transparent 50%,grey 0); */
}

.fab-card-header {
  height: 40px;
  width: 100%;
  font-size: 11px;
  text-align: center;
  position: relative;   
}

.pitch-container {
  position: absolute;
  left: 2px;
  top: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
}

.pitch-circle {
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%;
  position: absolute;
}

.card-cost {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid red;
  right: 1px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.card-type {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 30px;
  font-size: 10px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.card-stats {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fab-stat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-stats-img { height: 20px; width: 20px; }
.card-stats-img-small { height: 15px; width: 15px; }

.card-name { height: 100%; padding: 0 32px; }

.ml-3x { margin-left: 5px }
.mr-3x { margin-right: 5px }